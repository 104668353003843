import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from 'gatsby-plugin-react-i18next';
import Bio from "../components/bio"
import Layout from "../components/layout"
import Seo from "../components/seo"

// Components
import { Link, graphql } from "gatsby"

const Tags = ({ pageContext, data, location}) => {
  const {t} = useTranslation();
  const { tag } = pageContext
  const { edges, totalCount } = data.allMarkdownRemark
  const tagHeader = `${tag} (${totalCount})`

  const siteTitle = t(data.site.siteMetadata?.title || `NO PREFIX NOTES`)
  const siteUrl   = data.site.siteMetadata?.siteUrl || ``

  return (
    <Layout location={location} title={siteTitle} tags={data.tags.tags}>
      <article
        className="blog-post"
        itemScope
        itemType="http://schema.org/Article"
      >
        <header>
          <h1>{tagHeader}</h1>
        </header>

        <ul>
          {edges.map(({ node }) => {
            const { slug } = node.fields
            const { title } = node.frontmatter
            return (
              <li key={slug}>
                <Link to={slug}>{title}</Link>
              </li>
            )
          })}
        </ul>
 
      </article>
    </Layout>
  )

}

Tags.propTypes = {
  pageContext: PropTypes.shape({
    tag: PropTypes.string.isRequired,
  }),
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      totalCount: PropTypes.number.isRequired,
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            frontmatter: PropTypes.shape({
              title: PropTypes.string.isRequired,
            }),
            fields: PropTypes.shape({
              slug: PropTypes.string.isRequired,
            }),
          }),
        }).isRequired
      ),
    }),
  }),
}

export default Tags

export const pageQuery = graphql`
  query($tag: String $language: String!) {
    locales: allLocale(
      filter: {language: {eq: $language}}
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    site {
      siteMetadata {
        title
      }
    }
    tags: allMarkdownRemark(
      limit: 2000
        filter: {
          fields: {langKey: {eq: $language}},
        }) {
        tags: group(field: frontmatter___tags) {
          fieldValue
          totalCount
        }
      }
    allMarkdownRemark(
      limit: 2000
      sort: {fields: [frontmatter___date], order: DESC}
      filter: {
        fields: {langKey: {eq: $language}},
        frontmatter: {tags: {in: [$tag]}}}
    ) {
      totalCount
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
          }
        }
      }
    }
  }
`
